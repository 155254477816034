.container {
    margin: 50px;
    max-width: 1000px;
}

.container span {
    /*color: #083EA7;*/
    cursor: pointer;
}

.spanBlue {
    color: #083EA7;
    cursor: pointer;
}

.conH2 {
    margin-bottom: 25px;
    font-size: 20px;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.top p {
    font-size: 1.5rem;
    font-weight: 500;
    color: black;
}

.txtInfo {
    margin: 30px 0px;
    color: rgba(38, 38, 38, 0.7);
}

.txtInfo>p {
    margin-bottom: 10px;
}

.txtInfo span {
    color: #083EA7;
    cursor: pointer;
}

.profile {
    border-radius: 5px;
    /*padding: 20px;*/
    /*max-width: 1000px;*/
    margin-top: 15px;
}

.form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
}

.firstName {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
}

.lastName {
    grid-column: 3 / 5;
    grid-row: 1 / 2;
}

.phonenumber {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
}

.patientId {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
}

.altpatientId {
    grid-column: 4 / 5;
    grid-row: 2 / 3;
}

.contactPhonenumber {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
}

.signal {
    grid-column: 3 / 5;
    grid-row: 3 / 4;
}

.formbottom {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
    margin-top: 15px;
}

.treatmentmethod {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.threshold {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
}

.ppmVisible {
    grid-column: 3 / 5;
    grid-row: 4 / 5;
}

.form div>input[type=text] {
    width: 100%;
    padding: 12px 20px;
    margin: 7px 0;
    /*display: inline-block;*/
    border: 1px solid #262626;
    border-radius: 3px;

    font-size: 1rem;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.0.6);
    /*margin-bottom: 5px;*/
}

.radio {
    padding: 12px 0px;
    margin: 7px 0;
}

.btn {
    background-color: #083EA7;
    border: none;
    color: white;
    padding: 0.8rem 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 14px 0px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    justify-self: end;
    margin-right: 10px;
}

.btnJump {
    background-color: #01153d;
    border: none;
    color: white;
    padding: 0.8rem 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 14px 0px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    justify-self: end;
    margin-right: 10px;
}

.btnJump:hover {
    opacity: 0.5;
}

.btnF {
    background-color: #083EA7;
    border: none;
    color: white;
    padding: 0.8rem 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 18px 20px 0px 0px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    justify-self: end;
}

.btnCancel {
    background-color: #fff;
    border: none;
    color: #262626;
    padding: 0.8rem 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 14px 0px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-self: start;
}

.btnSaveC {
    background-color: #083EA7;
    border: none;
    color: white;
    padding: 0.8rem 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 14px 0px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-self: start;


}

.btnSaveCRight {
    margin-right: 10px;
}

.btn:focus,
.btnF:focus {
    outline: none;
}

.btn:hover,
.btnF:hover {
    background-color: cornflowerblue;
}

.btn:active,
.btnF:active {
    background-color: rgb(5, 36, 94);
}

.btnSaveC:hover {
    background-color: cornflowerblue;
}

.form>label {
    /*font-size: 12px;*/
    color: #262626;
    color: yellow;
}

.smsInfoTxt {
    font-style: italic;
    color: rgba(38, 38, 38, 0.7);
    margin-left: 170px;
    margin-top: 40px;
    margin-right: 12px;
    font-size: 1.1rem
}

.flex {
    display: flex;
    align-items: center;
}

.flex>label {
    margin-right: 10px;
    ;
}

.stepFour span {
    color: #083EA7;
    cursor: pointer;
}

.msisdn>div {
    padding: 10px;
    border: 2px solid grey;
    width: 280px;
    margin-bottom: 20px;
}

.msisdn>p {
    padding-bottom: 15px;
}

.stepFour>h2 {
    margin-bottom: 50px;
}

.stepFour>p {
    margin: 25px 0px;
}

.ec_input {
    width: 200px;
    padding: 12px 20px;
    margin: 7px 0;
    margin-right: 18px;
    /*display: inline-block;*/
    border: 1px solid #262626;
    border-radius: 3px;
    font-size: 16px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.0.6);
}

.datePick_Btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 30px;
}

.datePick_Btn>button {
    margin-left: 20px;

}

.scheduleStartTR {
    font-size: 20px;
    font-weight: 500;
}

.scheduleItemsTR {
    color: #083EA7;
}


.scheduleTable {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin: 50px 0px;
}

.scheduleTable th {
    background: rgb(191, 191, 191, 0, 25);
    text-align: left;
    padding: 12px;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid #BFBFBF;
}

.scheduleTable td {
    padding: 9px;
}

.scheduleTable tr:nth-child(odd) {
    background-color: rgb(205, 212, 215, 0.25);
}

.flexRec {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.flexRec>h3 {
    margin-right: 10px;
    font-size: 18px;
    font-weight: bold;
}

.flexnrSms {
    display: flex;
    align-items: flex-start;
    /*justify-content: space-between;*/
}

.smsImg {
    margin-left: 80px;
    /*box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);*/
}

.radioTxtNej {
    margin-right: 5px;
}

.radioTxtJa {
    margin: 0px 5px 0px 15px;
}
