.container {
    margin: 50px;
    max-width: 1200px;
}

.top {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 20px;
}

.top h2 {
    font-size: 36px;
    font-weight: 500;
  }
.top p {
    font-size: 24px;
    font-weight: 500;
}
.messageTop {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
}
.messageTop > div {
    border: 1px solid #BFBFBF;
    padding: 15px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
.item1 {
    background-color: #FFFFFF;
    color: #083EA7;
}

.messageBox {
    height: 400px;
    background: #FFFFFF;
    border: 1px solid #BFBFBF;
    box-sizing: border-box;
    box-shadow: 0px 16px 24px rgba(12, 0, 51, 0.1);
    border-radius: 3px;
}
