.container {
    margin: 50px;
}

.top, .topSelect {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.topright {
    display: flex;
    align-items: center;
}

.cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 30px;
    column-gap: 32px;
    /*justify-items: start;*/

  }
  
.card {
    /*height: 330px;*/
    background-color: #fff;
    border: 1px solid #BFBFBF;
    box-shadow: 0px 16px 24px rgba(12, 0, 51, 0.1);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

  .card_img > img {
    max-width: 100%;
    max-height: auto;
    padding: 15px 15px 0px 15px;
    border-radius: 3px;
  }
  .result {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .result > p {
    color: #8A8A8A;
    /*font-size: 14px;*/
    margin-left: 6px;
  }
  .card_results > p {
    font-size: 1rem;
    font-weight: 500;
  }
  .vas {
    color: yellowgreen;
    font-size: 14px;
  }
  .green {
    color: #05C19F;
  }
  .red {
    color: red;
  }
  .yellow {
    color: orange;
  }
  
  .card_results {
      padding: 15px;
  }
/*
  .top h2 {
    font-size: 36px;
    font-weight: 500;
  }
  */
  .top p {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .topSelect h3 {
      font-size: 1rem;
      font-weight: 500;
  }
  .topright p {
      margin-left: 20px;
  }

  .btnMonth {
    background-color: #fff;
    border: none;
    color: #262626;
    padding: 10px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 14px 5px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    /*
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-self: start;
    */
  }
  .btnMonth:focus {
    outline: none;
  }
  .btnMonth:hover {
    background-color: rgb(171, 194, 238);
  }

  @media (max-width: 1200px) {
    .cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 900px) {
    .cards {
      grid-template-columns: 1fr;
    }
  }
  .dateRes {
    display: flex;
    margin-bottom: 7px;
    justify-content: space-between;
    color: #8A8A8A;
  }
  .date {
    color: #8A8A8A;
   
  }
  .time {
    text-align: right;
    font-style: italic;
  }