.head {
  padding: 15px;
  border-bottom: 1px solid #BFBFBF;
  background-color: #f1f1f1;
}

.headBtn {
  padding: 10px 15px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnMonth {
  background-color: #fff;
  border: none;
  color: #262626;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 14px 5px;
  cursor: pointer;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  outline: none;
}


.btnMonth:hover {
  background-color: cornflowerblue;
}
