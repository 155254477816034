.sidenav {
  /*height: 80vh;*/
  height: 100%;
  width: 230px;
  /*
    position: fixed;
    z-index: 1;
    /*top: 100px;
    left: 0px;
    */
  background-color: #fff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  /*overflow-x: hidden;*/
  /*padding-top: 20px;*/
  border: 1px solid #BFBFBF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top {
  background-color: #fff;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #BFBFBF;
}

.top>p {
  font-size: 1.0rem;
  color: #262626;
}

.sidenav a:visited,
.sidenav a:link {
  text-decoration: none;
  color: #262626;
}

.top:hover {
  background-image: linear-gradient(0deg, #F2F2F2 4.39%, rgba(242, 242, 242, 0) 100%);
  border-left: 3px solid #083EA7;
  cursor: pointer;
}

.house {
  margin-left: 22px;
  margin-right: 11px;
}

.patients {
  background-color: #fff;
  border-bottom: 1px solid #BFBFBF;
  height: 450px;
  overflow: scroll
}

.patients li {
  border-bottom: 1px solid #BFBFBF;
  padding: 11px;

}

.patients li:hover {
  /*background-color: rgb(5, 193, 159, 0.2);*/
  background-image: linear-gradient(0deg, #F2F2F2 4.39%, rgba(242, 242, 242, 0) 100%);
  border-left: 3px solid #083EA7;
  cursor: pointer;
}

.patients li:last-child {
  border-bottom: none;
}


.flex1 {
  flex: 1;
}

.bottom {
  display: flex;
  flex-direction: column;
}

.bottomsupport {
  background-color: rgb(5, 193, 159, 0.1);
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid #BFBFBF;
  border-bottom: 1px solid #BFBFBF;
  text-decoration: none;
}

.bottomsupport a.active {
  text-decoration: none;
  font-size: 30px;
}

.sidenav>a.active {
  text-decoration: none;
}

.bottomsupport:hover {
  background-color: rgb(5, 193, 159, 0.2);
  border-left: 3px solid #083EA7;
  cursor: pointer;
}

.support {
  margin-left: 22px;
  margin-right: 11px;
}

.bottomlogout {
  background-color: #fff;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bottomlogout:hover {
  /*background-color: #BFBFBF;*/
  background-image: linear-gradient(0deg, #F2F2F2 4.39%, rgba(242, 242, 242, 0) 100%);
  cursor: pointer;
}

.logoutsvg {
  margin-left: 22px;
  margin-right: 11px;
}

.clientSum_Con {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  background-color: #083EA7;
}

.bottomManDoc {
  /*background-color: #fff;*/
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #BFBFBF;
  text-decoration: none;
}

.bottomManDoc:hover,
.addPatient:hover {
  background-image: linear-gradient(0deg, #F2F2F2 4.39%, rgba(242, 242, 242, 0) 100%);
  border-left: 3px solid #083EA7;
  cursor: pointer;
}

.addPatient {
  /*background-color: rgba(8,62,167, 0.1);*/
  background-color: #fff;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #BFBFBF;

}

.ff {
  background-color: aqua;
  padding: 30px;
}

.act {
  background-image: linear-gradient(0deg, #F2F2F2 4.39%, rgba(242, 242, 242, 0) 100%);
  border-left: 3px solid #083EA7;
  cursor: pointer;
}