.signalTable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.signalTable th {
  background:rgb(191, 191, 191, 0,25);
  text-align: left;
  padding: 12px;
  font-size: 1rem;
  font-weight: 500;
  border-bottom: 1px solid #BFBFBF;
}

.signalTable td {
  /*border: 1px solid #dddddd;*/
  text-align: left;
  padding: 9px 12px;
  font-size: 1rem;
  font-weight: 400;
}

.signalTable tr:nth-child(odd) {
  background-color:rgb(205, 212, 215, 0.25);
}

.flex {
  display: flex;
  align-items: center;
}
.flex > p {
  margin-right: 10px;
}
