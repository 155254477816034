
.container {
    margin: 50px;
    max-width: 1800px;
}

.top {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 30px;
}
/*
.top h2 {
    font-size: 36px;
    font-weight: 500;
}
*/
.top p {
    font-size: 1.5rem;
    font-weight: 500;
}

/*
  .cal ul {
    margin: 0;
    padding: 0 0 0 1.5em;
  }
  
  .cal li {
    margin: 2.5em 0;
    padding: 0;
  }
  
  .cal b { /* used for event dates/times 
    margin-right: 3px;
  }
  */
  .demoapp {
    display: flex;
    min-height: 100%;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
  }
  
  .demoappsidebar {
    width: 200px;
    line-height: 1.5;
    /*background: #eaf9ff;*/
    background: white;
    border-right: 1px solid #d3e2e8;
  }
  
  .demoappsidebarsection {
    padding: 1.5em;
  }
  
  .demoappmain {
    flex-grow: 1;
    padding: 1.5em;
  }
  
  .fc { /* the calendar root */
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .symbol {
    height: 11px;
    width: 11px;
    margin-left: 3px;
  }
  
  .title {
    font-style: normal;
  }
  
  .description {
    /*color: rgba(38,38,38, 0.7);*/
    font-size: 0.7rem;
  }
  .eventCon {
    /*background-color: aqua;*/
    overflow: hidden;
  }
  .btnSend {
    background-color: #083EA7;
    border: none;
    color: white;
    padding: 0.8rem 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.75rem;
    margin: 15px 20px 0px 25px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
  
    justify-self: start;
  }

  .btnSend:hover {
    background-color: cornflowerblue;
}