.container {
    margin: 50px;
    max-width: 1200px;
}

.top {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 20px;
}

.top h2 {
    font-size: 36px;
    font-weight: 500;
  }
.top p {
    font-size: 24px;
    font-weight: 500;
}
.messageBox {
    height: 600px;
    background: #FFFFFF;
    border: 1px solid #BFBFBF;
    box-shadow: 0px 16px 24px rgba(12, 0, 51, 0.1);
    border-radius: 3px;
}