.header {
  @apply flex justify-between h-16 bg-white shadow-lg;
}

.nav {
  @apply flex flex-row;
}

.nav a {
  @apply px-6;

  line-height: 56px;
}

.nav a:hover,
.active {
  background-image: linear-gradient(0deg, #F2F2F2 4.39%, rgba(242, 242, 242, 0) 100%);
  border-bottom: 3px solid #083EA7;
}

.btnQuit {
  @apply flex items-center justify-between bg-white pr-6 pl-4 py-4 shadow-md rounded-full my-2 mr-4;
}

.btnQuit:hover {
  opacity: 0.5;
}

.btnQuit span {
  @apply ml-2;
}

.name {
  /*text-align: center;*/
  /*color: grey;*/
  font-size: 14;
  font-weight: bold;
}