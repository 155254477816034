.container {
    margin: 50px;
    max-width: 1200px;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

/*
.top h2 {
    font-size: 36px;
    font-weight: 500;
  }
*/
.controlBox {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 30px;
    column-gap: 30px;
    /*max-width: 900px;*/
}

.controlBox>div {
    /*height: 127px;*/
    border: 1px solid #BFBFBF;
    background: #FFFFFF;
    border-radius: 3px;
    box-shadow: 0px 16px 24px rgba(12, 0, 51, 0.1);
}

.item1 {
    height: 370px;
    grid-column: 1 / 5;
    grid-row: 1 / 2;
    overflow: scroll
}

.item2 {
    height: 200px;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    padding: 15px 23px;
}

.item2 h4 {
    /*font-size: 18px;*/
    font-weight: 500;
    margin-bottom: 20px;
}

.item2 h5 {
    /*font-size: 12px;*/
    font-weight: 500;
    margin-bottom: 5px;
}

.item2 p {
    /*font-size: 14px;*/
    font-weight: 400;
    line-height: 15px;
    color: #8A8A8A;
    margin-bottom: 14px;
}

.item3 {
    height: 200px;
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    padding: 1px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.item4 {
    height: 200px;
    grid-column: 4 / 5;
    grid-row: 2 / 3;
    padding: 1px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.item3 h4,
.item4 h4 {
    /*font-size: 18px;*/
    font-weight: 500;
    margin-bottom: 8px;
    text-align: center;
}

.item3 p,
.item4 p {
    /*font-size: 14px;*/
    color: #8A8A8A;
    text-align: center;
    line-height: 14px;
}

.item3 img,
.item4 img {
    margin-bottom: 13px;
}

.btnAddPatient {
    background-color: #083EA7;
    border: none;
    color: white;
    padding: 13px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 14px 0px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;

    justify-self: start;
}

.btnAddPatient:focus {
    outline: none;
}

.btnAddPatient:hover {
    background-color: cornflowerblue;
}

.item3:hover,
.item4:hover {
    background-image: linear-gradient(0deg, #F2F2F2 4.39%, rgba(242, 242, 242, 0) 100%);
    cursor: pointer;
}

.endedTable {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.endedTable th {
    background: rgb(191, 191, 191, 0, 25);
    text-align: left;
    padding: 12px;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 1px solid #BFBFBF;
}

.endedTable td {
    /*border: 1px solid #dddddd;*/
    text-align: left;
    padding: 7px;
    font-size: 1rem;
    font-weight: 400;
}

.endedTable tr:nth-child(odd) {
    background-color: rgb(205, 212, 215, 0.25);
}

.activeTable {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.activeTable th {
    background: rgb(191, 191, 191, 0, 25);
    text-align: left;
    padding: 0.85rem;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 1px solid #BFBFBF;
}

.activeTable td {
    /*border: 1px solid #dddddd;*/
    text-align: left;
    padding: 6px;
    padding-left: 0.85rem;
    font-size: 1rem;
    font-weight: 400;
}

.activeTable tr:nth-child(odd) {
    background-color: rgb(205, 212, 215, 0.25);
}

.nameRow {
    color: #083EA7;
    font-weight: 500;
    padding: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.nameRow:hover {
    text-decoration: underline;
}

.signalsCount {
    padding: 6px 0px;
    padding-left: 0.4rem;
    display: flex;
    align-items: center;
}

.btnReactivate {
    background-color: #083EA7;
    border: none;
    color: white;
    padding: 7px 22px;
    text-align: center;
    margin: 0px;
    text-decoration: none;
    display: inline-block;
    font-size: 0.85rem;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
}

.btnReactivate:hover {
    opacity: 0.5;
}

.endDate {
    color: #8A8A8A;
}

.bin {
    display: flex;
    align-items: center;
}

.endedTable img {
    margin-left: 10px;
}

.endedTable img:hover {
    opacity: 0.5;
    cursor: pointer;
}

/*-------- Nav styles --------*/
.nav ul {
    list-style-type: none;
    margin: 0;
    margin-left: 0px;
    padding: 0;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid #BFBFBF;
}

.nav li {
    float: left;
    /*border-right:1px solid #bbb;*/
}

.nav li:last-child {
    border-right: none;
}

.nav li a {
    display: block;
    color: #262626;
    /* Albus Black */
    text-align: center;
    padding: 19px 28px 20px 28px;
    text-decoration: none;
}

.nav li a:hover:not(.active) {
    /*background-color: #ddd;*/
    /*background-image: linear-gradient(to right, #05235E , #083EA7);*/
    background-image: linear-gradient(0deg, #F2F2F2 4.39%, rgba(242, 242, 242, 0) 100%);
    /*background-image: linear-gradient( #05235E , #083EA7);*/
    border-bottom: 3px solid #083EA7;
}


.act {
    /*background-color: #F2F2F2;*/
    /*background-image: linear-gradient( #F2F2F2, rgb(219, 210, 210));*/
    background-image: linear-gradient(0deg, #F2F2F2 4.39%, rgba(242, 242, 242, 0) 100%);
    border-bottom: 3px solid #083EA7;
}

.symbol {
    height: 22px;
    width: 22px;
    margin-left: 8px;
}

.modalFlex {
    display: flex;
    justify-content: space-evenly;
}

.BtnFlex {
    background-color: #083EA7;
    border: none;
    color: white;
    padding: 7px 85px;
    text-align: center;
    margin: 10px;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
}

.BtnFlex:hover {
    opacity: 0.5;
}

.signalSumflexEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.signalSum {
    display: flex;
    align-items: center;
    padding: 0.1vw 0.4vw;
    /* box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    border: 1px solid #BFBFBF; */
}

.hypertoni {
    color: crimson;
}

.alcohol {
    color: deepskyblue;
}

.features span {
    margin-right: 10px;
}

.modalHead {
    font-size: 14;
    margin: 20px 0px;
    /*font-weight: bold;*/
    max-width: 500px;
}

.name {
    /*text-align: center;*/
    /*color: grey;*/
    font-size: 14;
    font-weight: bold;
}

.btnModalCon {
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    padding: 15px
}

.btnModal {
    background-color: #083EA7;
    border: none;
    color: white;
    padding: 7px 22px;
    text-align: center;
    margin: 0px 5px;
    text-decoration: none;
    display: inline-block;
    font-size: 0.85rem;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
}

.btnModal:hover {
    opacity: 0.5;
}
