.container {
    margin: 50px;
    max-width: 1200px;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    ;
}

.box {
    display: grid;
    grid-template-columns: 160px auto;
    grid-template-rows: 270px 165px auto;
    gap: 30px;
}

.actions {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    height: 270px;
    border: 1px solid #BFBFBF;
    box-sizing: border-box;
    box-shadow: 0px 16px 24px rgba(12, 0, 51, 0.1);
    border-radius: 3px;
    background-color: #fff;
}

.main {
    padding: 5px;
}

.main p {
    text-align: center;
    padding: 8px 1px;
    border-radius: 100px;
    font-size: 12px;
    margin: 8px;
    color: #fff;
}

.checkbox {
    padding: 15px;
    font-size: 12px;
}

.p1 {
    background: #012873;
}

.p2 {
    background: #083EA7;
}

.p3 {
    background: #0D71E8;
}

.p4 {
    background: #86B4F9;
}

.p5 {
    background: #C8DBFF;
}

.schema {
    margin-top: 10px;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    border: 1px solid #BFBFBF;
    box-shadow: 0px 16px 24px rgba(12, 0, 51, 0.1);
    border-radius: 3px;
    background-color: #fff;

}

.show {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    height: 172px;
    border: 1px solid #BFBFBF;
    box-shadow: 0px 16px 24px rgba(12, 0, 51, 0.1);
    border-radius: 3px;
    background-color: #fff;
}

.BtnMarkSignal {
    display: flex;
    align-items: center;
    background-color: #083EA7;
    border: none;
    color: white;
    padding: 8px 22px;
    text-decoration: none;
    font-size: 0.85rem;
    margin: 8px 12px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    outline: none;
}

.BtnMarkSignal>p {
    margin-left: 10px;
}

.BtnMarkSignal p,
.BtnMarkSignal svg {
    color: #fff;
}

.BtnMarkSignal:hover {
    background-color: cornflowerblue;
}

.BtnGetSignal {
    background-color: rgb(205, 212, 215, 0.25);
    border: none;
    color: white;
    padding: 10px 22px;
    text-decoration: none;
    font-size: 1rem;
    margin: 10px 12px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    outline: none;
}

.BtnGetSignal:hover {
    background-color: cornflowerblue;
    color: white;
}

.BtnX {
    padding: 7px 11px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 8px 12px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    outline: none;
}

.modalFlex {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.page {
    flex-Direction: 'row';
    background-Color: '#E4E4E4';
}

.section {
    margin: 10;
    padding: 10;
    flex-Grow: 1;
}

.flex {
    display: flex;
    align-items: center;
}

.flex>p {
    margin-right: 10px;
}

.demoapp {
    display: flex;
    min-height: 100%;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
}

.demoappsidebar {
    width: 200px;
    line-height: 1.5;
    background: #eaf9ff;
    border-right: 1px solid #d3e2e8;
}

.demoappsidebarsection {
    padding: 1.5em;
}

.demoappmain {
    flex-grow: 1;
    padding: 1.5em;
}

.fc {
    /* the calendar root */
    max-width: 1200px;
    margin: 0 auto;
}

.symbolCalendar {
    height: 11px;
    width: 11px;
    margin-left: 3px;
}
