.container {
    margin: 50px;
    max-width: 1000px;
}

.container h2 {
    /*font-size: 36px;*/
    /*font-weight: 500;*/
    margin-bottom: 25px;
    ;
}

.news {
    color: red;
    margin-bottom: 22px;
    font-weight: 400;
}

.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 5px 5px 17px 17px;
    row-gap: 30px;
    column-gap: 30px;
    max-width: 900px;
    margin-top: 20px;
}

.wrapper>div {
    /*height: 127px;*/
    border: 1px solid #BFBFBF;
    background: #FFFFFF;
    border-radius: 3px;
}

.support {
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
}

.supportTxtHead {
    font-size: 1.2rem;
    font-weight: 500;
    margin-left: 15px;
}

.supportTxtCont {
    font-size: 1.1rem;
    font-weight: 400;
    margin-left: 15px;
    color: #083EA7;
}

.wrapper h3 {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    align-self: flex-end;
    font-size: 24px;
    font-weight: 500;
}

.item4 {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    font-size: 20px;
    padding: 15px 13px;
}

.item5 {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    font-size: 20px;
    padding: 15px 13px;
}

.item6 {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    font-size: 20px;
    padding: 15px 13px;
}

.item7 {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    font-size: 20px;
    padding: 15px 13px;
}

.item8 {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
    font-size: 20px;
    padding: 15px 13px;
}

.item9 {
    grid-column: 3 / 4;
    grid-row: 4 / 5;
    font-size: 20px;
    padding: 15px 13px;
}

.container h3 {
    font-size: 24px;
    font-weight: 500;
}

.container h4 {
    font-size: 24px;
    font-weight: 500;
    margin-top: 70px;
    margin-bottom: 25px;
}

.item4:hover,
.item5:hover,
.item6:hover,
.item7:hover,
.item8:hover,
.item9:hover {
    background-color: #083EA7;
    color: #FFFFFF;
}

.container select {
    background: #FFFFFF;
    /* Albus Gray 400 */
    border: 1px solid #BFBFBF;
    box-sizing: border-box;
    border-radius: 3px;
    width: 450px;
    display: block;
    margin-bottom: 12px;
}


.tecMan {
    margin-top: 28px;
    font-size: 20px;
    cursor: pointer;
}

.tecMan:hover {
    color: grey
}

.returnB {
    margin-bottom: 20px;
}

.returnB p {
    padding-left: 5px;
}

.returnB>h3 {
    color: black;
    font-size: 20px;
}

.returnB p:last-child {
    color: rgb(59, 51, 51);
    font-style: italic;
}

.returnB ul {
    margin: 10px;
}

.highlight {
    font-weight: 700;
}

.wrapperAction {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 60px;
    width: 500px;
}

.wrapperAction>.link {
    text-decoration: underline;
    font-size: 1.2rem;
    color: #083EA7;
    font-weight: 700;
}
