.container {
    background-color: #f2f2f2;
    padding-top: 138px;
    min-height: 100vh;

}

.loginTxt {
    font-size: 18px;
    margin-top: 20px;
}

.box {
    display: flex;
    background-color: #fff;
    width: 420px;
    height: 520px;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    padding: 50px;
    margin: auto;
    box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.3), 0px 10px 10px rgba(0, 0, 0, 0.14);
}

.form {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: space-between;
}

.txt {
    font-size: 0.75rem;
    line-height: 13px;
    color: #8A8A8A;
    margin-bottom: 5px;
}

.txt_link {
    color: #083EA7;
    align-self: flex-start;
    font-size: 12px;
    line-height: 13px;
}

.forminput {
  width: 100%;
}

.forminput>input[type="text"] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    font-size: 1rem;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.forminput>input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0px;
    font-size: 1rem;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
