.container {
    display: grid;
    grid-template-columns: 200px 400px;
    margin: 80px;
    gap: 30px 30px;
}

.itemA {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    width: 450px;
}

.imgMob {
    width: 200px;
}

.itemB {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    color: #262626;
}

.hInsight {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 40px;
}

.pInsight {
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 500;
    font-family: sans-serif;
}

.itemB span {
    color: #083EA7;
    cursor: pointer;
    text-decoration: underline;
}

.content {
    font-size: 17px;
}