.container {  
  margin: 50px;
  max-width: 1000px;
}


 
.profile {
    border-radius: 5px;;
    padding: 20px 0px;
    max-width: 900px;
}

/*
.form > input[type="text"], select {
    width: 350px;
    padding: 12px 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    display: inline-block;
    border: 1px solid #262626;
    border-radius: 4px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);    
}
*/

.form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
  }

.form div > input[type=text], select, input[type=email] {
    width: 100%;
    padding: 12px 20px;
    margin: 7px 0;
    /*display: inline-block;*/
    border: 1px solid #262626;
    border-radius: 3px;
   
    font-size: 1rem;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.0.6);
    /*margin-bottom: 5px;*/
   
  }

.arbets {
    width: 80%;
}

.txtInfo {
  margin: 30px 0px;
  color: rgba(38,38,38, 0.7);
}
.txtInfo > p {
  margin-bottom: 10px;
}
.txtInfo span {
  color: #083EA7;
  cursor: pointer;
}
  
.btn {
    background-color: #083EA7;
    border: none;
    color: white;
    padding: 0.8rem 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 14px 0px;
    cursor: pointer;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    
    justify-self: start;
  }

.btn:focus {
    outline: none;
  }

.btn:hover {
    background-color: cornflowerblue;
  }
.btn:active {
    background-color: rgb(5, 36, 94);
  }

.form > label {
      font-size: 12px;
      color: #262626;
  }
