.readAtGray {
  color: #8A8A8A;
}

.symbol {
  height: 22px;
  width: 22px;
}

.offlineIcon {
  position: absolute;
  height: 15px;
  width: 15px;
  bottom: 6px;
  left: 26px;
}

.readAt {
  font-style: italic;
}
